<template>
  <v-card>
    <v-data-table
      :headers="tableHeaders"
      :items="tableContents"
      :loading="loading"
      :search="search"
      dense
    >
      <template v-slot:top>
        <v-card-title class="d-flex align-center">
          Daftar Penyumbang
          <v-spacer />
          <v-text-field
            label="Cari"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            clearable
          />
        </v-card-title>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        {{ formatCurrency(item.amount) }}
      </template>

      <template v-if="sheetData.length" v-slot:[`body.append`]>
        <tr>
          <td class="font-weight-medium">Total</td>
          <td class="font-weight-medium text-right">
            <span class="text-decoration-underline" v-text="formatCurrency(sum)" />
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-card-text class="text-caption">
      Anda dapat mengambil spreadsheet data penyumbang di
      <a target="_blank" :href="gSheetUrl">Data Lengkap Penyumbang AmborJo Peduli</a>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CovidBeneficiaryTable',

  data () {
    return ({
      beneficiarySpreadsheetId: '10rRlBm6IeQ4_vWQLtmnHu2053xQ0WlDVjYFo3uo3BpE',
      gSheetUrl: 'https://docs.google.com/spreadsheets/d/10rRlBm6IeQ4_vWQLtmnHu2053xQ0WlDVjYFo3uo3BpE/edit?usp=sharing',

      sheetData: [],
      loading: false,
      search: '',

      tableHeaders: [
        {
          text: 'Nama Penyumbang',
          value: 'name'
        },
        {
          text: 'Jumlah',
          value: 'amount',
          align: 'end',
          filterable: false
        }
      ]
    })
  },

  computed: {
    gSheetApiKey () {
      return process.env.VUE_APP_GOOGLE_API_KEY
    },
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    },

    tableContents () {
      return this.$data.sheetData
        .filter(v => v.length)
        .filter(v => (v[0].toUpperCase() !== 'TOTAL'))
        .map(v => ({
          name: v[0],
          amount: v[2]
        }))
    },

    sum () {
      if (this.$data.sheetData.length) {
        return this.$data.sheetData.find(v => v.length && v[0].toUpperCase().trim() === 'TOTAL')[2]
      } else {
        return 0
      }
    }
  },

  methods: {
    formatCurrency (num) {
      return Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(num)
    },

    getTableData () {
      const SHEET_NAME = 'DAFTAR PENYUMBANG'
      const DATA_RANGE = 'C5:E999'
      var requestUrl = new URL(`https://sheets.googleapis.com/v4/spreadsheets/${this.$data.beneficiarySpreadsheetId}/values/${SHEET_NAME}!${DATA_RANGE}`)
      requestUrl.searchParams.append('key', this.gSheetApiKey)
      requestUrl.searchParams.append('valueRenderOption', 'UNFORMATTED_VALUE')

      return new Promise((resolve, reject) => {
        window.fetch(requestUrl)
          .then(response => response.json())
          .then(data => {
            // data.values.filter(v => (v[0].toUpperCase() !== 'JUMLAH'))
            resolve(data.values)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    try {
      this.$data.sheetData = await this.getTableData()
    } catch (e) {
      // console.error(e)
      this.$store.commit('toast/setError', 'Gagal mengambil data penyumbang.')
    }
    this.$data.loading = false
  }
}
</script>
