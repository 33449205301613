<template>
  <div class="d-flex flex-column">
    <jumbotron :aspect-ratio="isOnPC ? undefined : 1.6"
      :src="`https://storage.googleapis.com/moonhope-174701.appspot.com/${isOnPC ? 'photo_2020-06-21_11-13-59.jpg' : 'photo_2020-06-21_11-13-59_m.jpg'}`"
    >
      <!-- <v-container
        class="fill-height ma-0 white--text"
        :class="{
          'pa-3': isOnPC,
          'pa-0': !isOnPC
        }"
      >
        <v-row class="fill-height pa-0 ma-0" align="end" justify="end" no-gutters>
          <v-col class="shadow">
            <v-sheet
              color="rgba(0, 0, 0, 0.5"
              class="white--text pa-4 ma-0"
              :width="isOnPC ? '80%' : '100%'"
            >
              <h1
                :class="isOnPC ? 'display-3' : 'display-1' "
              >
                AmborJo Peduli
              </h1>
              <p :class="isOnPC ? 'headline' : ''">for torang pe sudara-sudara yang kena dampak COVID-19</p>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container> -->
    </jumbotron>
    <v-container>
      <p>
        Dalam rangka membantu saudara-saudara kita yang dilanda kesulitan selama wabah COVID-19,
        AmborJo menyalurkan sejumlah paket bantuan yang disebarluaskan kepada mereka yang membutuhkan.
        Terutama, mereka yang mata pencahariannya terdampak oleh COVID-19.
      </p>
      <!-- <p>
        <a href="https://docs.google.com/spreadsheets/d/10rRlBm6IeQ4_vWQLtmnHu2053xQ0WlDVjYFo3uo3BpE/edit?usp=sharing">Daftar Penyumbang</a>
      </p> -->
      <photo-grid prefix="covid" />
      <beneficiary-table class="mt-4" />
    </v-container>
  </div>
</template>

<script>
import Jumbotron from '../components/Jumbotron'
import GCSPhotoGrid from '../components/gcsPhotoGallery'
import CovidBeneficiaryTable from '../components/Covid/CovidBeneficiaryTable'

export default {
  name: 'CovidPage',

  components: {
    'photo-grid': GCSPhotoGrid,
    jumbotron: Jumbotron,
    'beneficiary-table': CovidBeneficiaryTable
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  beforeMount () {
    document.title = 'AmborJo for torang pe sudara-sudara'
  },

  metaInfo () {
    return ({
      meta: [
        {
          property: 'og:title',
          content: 'AmborJo Peduli'
        },
        {
          property: 'og:image',
          content: 'https://storage.googleapis.com/moonhope-174701.appspot.com/Untitled%20design%20(3).jpg'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:description',
          content: 'Dalam rangka membantu saudara-saudara kita yang dilanda kesulitan selama wabah COVID-19, AmborJo menyalurkan sejumlah paket bantuan yang disebarluaskan kepada mereka yang membutuhkan. Terutama, mereka yang mata pencahariannya terdampak oleh COVID-19.'
        },
        {
          property: 'og:url',
          content: window.location.href
        }
      ]
    })
  }
}
</script>
