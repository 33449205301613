<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0" align="center" justify="center" v-if="loading">
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <v-row justify="stretch" align="center" :no-gutters="noGutters" class="pa-0 ma-0" v-else>
      <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2" v-for="(photo, n) in photos" :key="n">
        <v-card dark :href="photo.url">
          <v-img contain aspect-ratio="1" :src="photo.thumbnailUrl">
            <template v-slot:placeholder>
              <v-container fluid class="fill-height pa-0 ma-0">
                <v-row class="pa-0 ma-0 fill-height" justify="center" align="center">
                  <v-progress-circular indeterminate>
                  </v-progress-circular>
                </v-row>
              </v-container>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../plugins/firebase'

const FUNCTION_NAME = 'getPhotosWithPrefix'
const BUCKET_ADDRESS = 'https://storage.googleapis.com/moonhope-174701.appspot.com'

export default {
  name: 'GCSPhotoGallery',

  props: {
    prefix: {
      type: String,
      required: true
    },
    'no-gutters': Boolean
  },

  data () {
    return ({
      loading: false,
      photos: []
    })
  },

  methods: {
    getPhotos () {
      return firebase.functions().httpsCallable(FUNCTION_NAME)(this.prefix)
    }
  },

  async mounted () {
    this.$data.loading = true

    try {
      var gcsObjects = (await this.getPhotos()).data
    } catch (e) {
      // console.error(e)
      this.$store.commit('toast/setError', 'Cannot get photos.')
    }

    var images = gcsObjects.filter(object => !object.match(/\/$/))

    var photos = images.filter(objectName => {
      return !objectName.match(/\/thumbnails\//)
    })

    var thumbnails = images.filter(objectName => {
      return objectName.match(/\/thumbnails\//)
    })

    this.$data.photos = photos.map((photo, i) => ({
      url: `${BUCKET_ADDRESS}/${photo}`,
      thumbnailUrl: `${BUCKET_ADDRESS}/${thumbnails[i]}`
    }))

    this.$data.loading = false
  }
}
</script>
